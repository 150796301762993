import React from "react"
import Layout from "../../components/layout"
import { graphql } from "gatsby"
import * as S from "./news.template.styles"
import BlogHeader from "../../layouts/BlogHeader/blog-header.component"
import ShareSocialMediaIcons from "../../components/share-social-media-icons/share-social-media-icons.component"
import AboutNewsBanner from "../../layouts/AboutNewsBanner/about-news-banner.component"
import NewsPage from "../../layouts/NewsPage/news-page.component"
import SEO from "../../components/seo/seo.component";

export const query = graphql`
  query NewsQuery($id: String!) {
    wpNew(id: { eq: $id }) {
      title
      date(formatString: "MMMM DD, YYYY")
      content
      featuredImage {
        node {
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      newsInfo {
        link
        date
      }
      seo {
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          sourceUrl
          title
        }
      }
      id
      uri
    }
  }
`

export const Head = ({ data }) => <SEO data={data?.wpNew?.seo} />

const News = ({ data }) => {
  const { title, content, featuredImage, date, uri, id, newsInfo } =
    data.wpNew
  return (
    <Layout>
      <BlogHeader
        title={title}
        image={featuredImage?.node}
        date={newsInfo?.date}
      />
      <S.Wrapper>
        <S.CustomContainer>
          <ShareSocialMediaIcons
            className="desktop"
            slug={`${process.env.GATSBY_WEBSITE_URL}${uri}`}
          />
          <S.Content dangerouslySetInnerHTML={{ __html: content }} />
          <ShareSocialMediaIcons
            className="mobile"
            slug={`${process.env.GATSBY_WEBSITE_URL}${uri}`}
          />
        </S.CustomContainer>
      </S.Wrapper>
      <AboutNewsBanner
        topTitle="About PostEra"
        title="PostEra is building a modern 21st century biopharma."
        content="<p>We use our advances in machine learning to accelerate medicinal 
        chemistry and bring more cures to patients. PostEra advances small molecule 
        programs through partnerships with biopharma while also advancing its own internal 
        pipeline. We’ve raised $26M from top investors and closed $1Bn in AI partnerships, 
        signing multi-year agreements with <a href='https://endpts.com/amgen-lines-up-new-ai-biotech-partner-in-postera/' target='_blank'>Amgen</a>, 
        <a href='https://endpts.com/a-new-ai-startup-has-emerged-and-pfizer-likes-what-it-sees/' target='_blank'>Pfizer</a> and the 
        <a href='https://endpts.com/nih-grants-over-500m-to-create-antivirals-for-future-pandemics/' target='_blank'>NIH</a>. PostEra also launched 
        and helped lead the world's largest open-science drug discovery effort; 
        <a href='https://postera.ai/moonshot/' target='_blank'>COVID Moonshot</a>.</p>"
      />
      <NewsPage id={id} />
    </Layout>
  )
}

export default News
