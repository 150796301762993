import React from "react"
import * as S from "./about-news-banner.styles"
import parser from "html-react-parser"

const AboutNewsBanner = ({ topTitle, title, content }) => {
  return (
    <S.Section>
      <S.Wrapper maxWidth="md">
        <S.ContentContainer>
          <S.TopTitle>{topTitle}</S.TopTitle>
          <S.Title>{title}</S.Title>
          <S.Description>{parser(content)}</S.Description>
        </S.ContentContainer>
      </S.Wrapper>
    </S.Section>
  )
}

export default AboutNewsBanner
