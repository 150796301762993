import React from "react"
import * as S from "./blog-header.styles"
import { Stack } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import moment from "moment"

const BlogHeader = ({ title, subtitle, image, date }) => {
  const staticQuery = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "news-bg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      left: file(relativePath: { eq: "left-molecule-news.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      right: file(relativePath: { eq: "right-molecule-news.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)
  return (
    <S.Section>
      <S.BgImage img={staticQuery.bg} tag="div">
        <S.ComplementaryImage img={staticQuery?.left} className="left" />
        <S.ComplementaryImage img={staticQuery?.right} className="right" />
        <S.CustomContainer>
          {title && <S.Title variant="h1">{title}</S.Title>}
          {date && <S.Date> {moment(date).format("MMMM DD, YYYY")}</S.Date>}
          {subtitle && <h3>{subtitle}</h3>}
        </S.CustomContainer>
        {/* <S.ImageContainer>
          <S.Image img={image} alt="header-image" />
        </S.ImageContainer> */}
      </S.BgImage>
    </S.Section>
  )
}

export default BlogHeader
