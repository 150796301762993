import styled from "@emotion/styled"
import Container from "@mui/material/Container"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  background: white;
  /* padding-top: 0 !important; */
`

export const CustomContainer = styled(Container)`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up("md")} {
    /* padding-top: 1.5rem; */
    flex-direction: row;
    justify-content: center;
  }
`

export const Content = styled.div`
  color: #081107;

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 630px;
  }

  p {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  h2 {
    color: #081107;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
  h3 {
    margin-top: 0.5rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 0;

    ${({ theme }) => theme.breakpoints.up("md")} {
      margin-top: 1.5rem;
      margin-bottom: 0.25rem;
      font-size: 2rem;
      line-height: ${({ theme }) => theme.typography.pxToRem(38)};
    }
  }
  p:first-child {
    margin-top: 0;
  }
  //li {
  //  color: #64748b;
  //}
`
