import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container, Typography } from "@mui/material"

export const Section = styled(SectionWrapper)`
  background: ${({ theme }) => theme.palette.primary.main};
`

export const Wrapper = styled(Container)``

export const ContentContainer = styled.div`
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-left: 253px;
  }
`

export const TopTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  font-weight: 600;
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
  height: 41px;
  border-left: 2px solid ${({ theme }) => theme.palette.primary.light};
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
`

export const Title = styled(Typography)`
  margin-top: 1.5rem;
  color: ${({ theme }) => theme.palette.primary.light};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(48)};
  font-weight: 400;
  line-height: 120%;
`

export const Description = styled.div`
  margin-top: 1.5rem;
  p,
  a {
    color: ${({ theme }) => theme.palette.primary.light};
    font-size: ${({ theme }) => theme.typography.pxToRem(18)};
    font-weight: 400;
    line-height: ${({ theme }) => theme.typography.pxToRem(32)};
  }

  a {
    color: ${({ theme }) => theme.palette.primary.light};
    font-weight: 600;
    text-decoration: underline;
  }
`
