import styled from "@emotion/styled"
import { Alert, Typography } from "@mui/material"
import Container from "@mui/material/Container"
import CustomButton from "../custom-button/custom-button.component"

export const Wrapper = styled.div`
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin: 0 6.25rem 0 0;
  }

  &.desktop {
    display: none;
    ${({ theme }) => theme.breakpoints.up("md")} {
      display: block;
    }
  }

  &.mobile {
    display: block;
    ${({ theme }) => theme.breakpoints.up("md")} {
      display: none;
    }
  }
`

export const CustomContainer = styled(Container)`
  ${({ theme }) => theme.breakpoints.up("md")} {
    position: sticky;
    top: 9rem;
  }

  &.desktop {
    display: none;
    ${({ theme }) => theme.breakpoints.up("md")} {
      display: block;
    }
  }

  &.mobile {
    display: block;
    ${({ theme }) => theme.breakpoints.up("md")} {
      display: none;
    }
  }
`

export const Button = styled(CustomButton)`
  padding: ${({ theme }) => theme.typography.pxToRem(9)}
    ${({ theme }) => theme.typography.pxToRem(18)} !important;

  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  &:hover {
    background-color: white !important;

    div {
      color: ${({ theme }) => theme.palette.primary.main};
    }
    svg {
      g {
        path {
          stroke: ${({ theme }) => theme.palette.primary.main};
        }
      }
    }
  }

  svg {
    width: 20px;
    height: 20px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    svg {
      width: 24px;
      height: 24px;
    }
    padding: ${({ theme }) => theme.typography.pxToRem(14)}
      ${({ theme }) => theme.typography.pxToRem(25)} !important;
  }
`

export const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  // span {
  //   font-weight: 600;
  //   color: ${({ theme }) => theme.palette.secondary.main};
  // }

  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: normal;

  color: ${({ theme }) => theme.palette.text.light};

  svg {
    min-height: 24px;
    min-width: 24px;
  }
`

export const CustomAlert = styled(Alert)`
  background-color: ${({ theme }) => theme.palette.primary.main};
`

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1rem;
  margin-bottom: 1.25rem;

  flex-direction: column;
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 630px;
    margin-top: 0;

    margin-bottom: 0;
    align-items: flex-start;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 2.5rem;
  flex-direction: column;
  width: 100%;
  align-items: center;
  ${({ theme }) => theme.breakpoints.up("md")} {
    gap: 1.5rem;
  }
`

export const IconRow = styled.div`
  display: flex;
  gap: 0.5em;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* ${({ theme }) => theme.breakpoints.up("sm")} {
    justify-content: flex-start;
  } */
`
export const IconWrapper = styled.a`
  text-decoration: none;
  border-radius: 50%;
  /* background-color: ${({ theme }) => theme.palette.primary.main}; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Label = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #191d23;
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(19)};
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-bottom: ${({ theme }) => theme.typography.pxToRem(12)};
  }
`
