import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import CustomImage from "../../components/custom-image/custom-image.component"
import { Container, Typography } from "@mui/material"
import CustomBgImage from "../../components/custom-bg-image/custom-bg-image.component"

export const Section = styled(SectionWrapper)`
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  height: 500px;
`

export const Date = styled.span`
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: 100%;
  color: #fff;
  opacity: 0.75;
  font-weight: 400;
  text-align: center;
  padding-top: 2rem;
  z-index: 5;
`

export const Title = styled(Typography)`
  color: #ffffff;
  max-width: 937px;
  align-self: center;
  z-index: 5;
  font-family: ${({ theme }) => theme.fonts.secondary};

  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: center;
  padding: 0 1rem;

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 0;
    font-size: ${({ theme }) => theme.typography.pxToRem(48)};
    font-weight: 400;
    line-height: 120%;
  }
`

export const Image = styled(CustomImage)`
  position: relative;
  padding-top: ${({ theme }) => theme.typography.pxToRem(62)};
  width: 100%;
  height: 500px;
  overflow: hidden;
  border-radius: 8px;

  img {
    width: 100%;
    height: 500px;
    object-fit: cover !important;
    border-radius: 8px;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 900px;
    /* height: 500px; */

    img {
      width: 900px;
      height: 500px;
      object-fit: cover !important;
    }
  }
`

export const ImageContainer = styled(Container)`
  display: flex;
  justify-content: center;
  padding-top: 2rem;
`

export const BgImage = styled(CustomBgImage)`
  position: relative;
  height: 500px;
  overflow: unset;
  z-index: -1;
`

export const CustomContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;

  /* padding-top: ${({ theme }) => theme.typography.pxToRem(90)}; */
`

export const ComplementaryImage = styled(CustomImage)`
  position: absolute;
  z-index: -1;
  &.left {
    left: 0;
    top: -30%;
    width: 649px;
    height: 796px;
    img {
      width: 649px;
      height: 796px;
    }
  }
  &.right {
    right: 250px;
    top: 200px;
    width: 279px;
    height: 341px;
    img {
      width: 279px;
      height: 341px;
      object-fit: contain !important;
    }
  }

  ${({ theme }) => theme.breakpoints.down(1000)} {
    &.left {
      width: 300px;
      height: 500px;
      top: 15%;
      img {
        width: 300px;
        height: 500px;
      }
    }
    &.right {
      right: 0;
      width: 150px;
      height: 250px;
      img {
        width: 150px;
        height: 250px;
      }
    }
  }
`
