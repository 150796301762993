import React from "react"
import * as S from "./share-social-media-icons.styles"
import FacebookIcon from "../../assets/social/facebook-blue.svg"
import LinkedInIcon from "../../assets/social/linkedin-blue.svg"
import XIcon from "../../assets/social/x-icon-blue.svg"
import TelegramIcon from "../../assets/social/telegram-blue.svg"
import CopyIcon from "../../assets/copy.svg"
import { isBrowser } from "../../utils/utils"
import Snackbar from "@mui/material/Snackbar"

const ShareSocialMediaIcons = ({ show, slug, className }) => {
  const [alertOpen, setAlertOpen] = React.useState(false)
  const CopyToClipboard = toCopy => {
    if (isBrowser) {
      const el = document.createElement(`textarea`)
      el.value = toCopy
      el.setAttribute(`readonly`, ``)
      el.style.position = `absolute`
      el.style.left = `-9999px`
      document.body.appendChild(el)
      el.select()
      document.execCommand(`copy`)
      document.body.removeChild(el)
    }
  }
  return (
    <S.Wrapper hiddeOnMobile={show} className={className}>
      <Snackbar
        open={alertOpen}
        autoHideDuration={2000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <S.CustomAlert
          onClose={() => setAlertOpen(false)}
          severity="success"
          variant="filled"
        >
          Link copied
        </S.CustomAlert>
      </Snackbar>
      <S.CustomContainer disableGutters={true} className={className}>
        <S.InfoWrapper>
          {/*<S.Label>Share via: </S.Label>*/}
          <S.ButtonsWrapper className={className}>
            <S.Button
              onClick={() => {
                CopyToClipboard(isBrowser && window.location.href)
                setAlertOpen(true)
              }}
            >
              <S.CopyWrapper>
                <CopyIcon />
                Copy&nbsp;Link
              </S.CopyWrapper>
            </S.Button>
            <S.IconRow>
              <S.IconWrapper
                href={`https://t.me/share/url?url=${
                  process.env.GATSBY_WEBSITE_URL ? slug : "/blog" + slug
                }`}
                target={"_blank"}
              >
                <TelegramIcon />
              </S.IconWrapper>
              {/* <S.IconWrapper
                href={`https://www.facebook.com/sharer/sharer.php?u=${
                  process.env.GATSBY_WEBSITE_URL ? slug : "/blog" + slug
                }`}
                target={"_blank"}
              >
                <FacebookIcon />
              </S.IconWrapper> */}
              <S.IconWrapper
                href={`https://twitter.com/intent/tweet?&url=${
                  process.env.GATSBY_WEBSITE_URL ? slug : "/blog" + slug
                }`}
                target={"_blank"}
              >
                <XIcon />
              </S.IconWrapper>
              <S.IconWrapper
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${
                  process.env.GATSBY_WEBSITE_URL ? slug : "/blog" + slug
                }`}
                target={"_blank"}
              >
                <LinkedInIcon />
              </S.IconWrapper>
            </S.IconRow>
          </S.ButtonsWrapper>
        </S.InfoWrapper>
      </S.CustomContainer>
    </S.Wrapper>
  )
}

export default ShareSocialMediaIcons
